<script setup lang="ts">
import { ChatIcon } from '@glow/shared/components/icons';
import { useRoutes } from '@glow/shared/lib/routes';

const { getUrl } = useRoutes();
</script>

<template>
  <AppButton
    :to="getUrl('chat')"
    class="messages-button"
    color="light"
    :icon-start="ChatIcon"
  />
</template>

<style lang="scss" scoped>
.messages-button {
  position: relative;

  &__count-background,
  &__count {
    position: absolute;
    padding: 0 3px;
    top: 6px;
    right: 4px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
  }

  &__count-background {
    background-color: #fff;
    border-radius: 5px;
  }
}
</style>
